import Select from "react-select";
import PropTypes from "prop-types";
import colors from "assets/theme/base/colors";
import linearGradient from "assets/theme/functions/linearGradient";

const VuiSelect = ({ options, onChange }) => {
  const { transparent, light, info, grey, gradients } = colors;
  return (
    <Select
      options={options}
      className="vui-select"
      defaultValue={options[0]}
      classNamePrefix="vui"
      onChange={onChange}
      styles={
        {
          control: (styles) => ({
            ...styles,
            backgroundColor: grey[900],
            marginRight: "0.8rem",
            borderColor: "transparent",
            borderStartEndRadius: "0.7rem",
            borderEndEndRadius: "0.7rem",
            borderEndStartRadius: "0.7rem",
            borderStartStartRadius: "0.7rem",
            boxShadow: "none",
            fontSize: "0.75rem",
            borderRadius: "0.25rem",
            borderStyle: "solid",
            padding: "0.1rem",
            "&:hover": {
              borderColor: "transparent",
            },

          }),
          indicatorSeparator: (styles) => ({
            ...styles,
            display: "none",
          }),
          dropdownIndicator: (styles) => ({
            ...styles,
            color: grey[500],
            padding: "0rem",
            scale: "0.8",
          }),
          singleValue: (styles) => ({
            ...styles,
            color: light.main,
          }),
          menu: (styles) => ({
            ...styles,
            background: linearGradient(gradients.dark.main, gradients.dark.state),
            color: light.main,
            fontSize: "0.875rem",
          }),
          option: (styles, { isFocused, isSelected }) => ({
            ...styles,
            backgroundColor: transparent.main,
            cursor: "pointer",
            "&:active": {},
          }),
        }
      }
    />
  );
};

VuiSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func,
};

export default VuiSelect;
