import Footer from "../../layouts/Footer";

import DashboardLayout from "../../layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../layouts/Navbars/DashboardNavbar";
import VuiBox from "components/VuiBox";
import Card from "@mui/material/Card";
import VuiTypography from "components/VuiTypography";
import DataTable from "../../layouts/Tables/DataTable";
import React from "react";
import { WebGetRequest } from "api/request";
import { prettierRows } from "../../utils/prettier";

function Server(props) {
  const [rows, setRows] = React.useState({
    chat: [],
    command: [],
  });

  React.useEffect(() => {
    (async () => {
      setRows(await WebGetRequest("/api/server/" + props.match.params.name));
    })();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <VuiBox mb={3}>
          <Card>
            <DataTable title="Messages" columns={[
              { name: "id", align: "center" },
              { name: "username", align: "center" },
              { name: "message", align: "center" },
              { name: "server", align: "center" },
              { name: "date", align: "center" },
            ]} rows={prettierRows(rows.chat)} />
          </Card>
        </VuiBox>
      </VuiBox>
      <VuiBox py={3}>
        <VuiBox mb={3}>
          <Card>
            <DataTable title="Commands"  columns={[
              { name: "id", align: "center" },
              { name: "username", align: "center" },
              { name: "command", align: "center" },
              { name: "server", align: "center" },
              { name: "date", align: "center" },
            ]} rows={prettierRows(rows.command)} />
          </Card>
        </VuiBox>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Server;
