import { useEffect } from "react";


import { useLocation } from "react-router-dom";


import PropTypes from "prop-types";


import VuiBox from "components/VuiBox";


import { setLayout, useVisionUIController } from "context";

function PageLayout({ children }) {
  const [, dispatch] = useVisionUIController();
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, "page");
  }, [pathname]);

  return (
    <VuiBox
      width="100vw"
      maxWidth="100%"
      height="100%"
      minHeight="100vh"
      sx={({ functions: { tripleLinearGradient }, palette: { gradients } }) => ({
        overflowX: "hidden",
        backgroundImage: tripleLinearGradient(
          gradients.cover.main,
          gradients.cover.state,
          gradients.cover.stateSecondary,
          gradients.cover.deg,
        ),
        position: "relative",
      })}
    >
      {children}
    </VuiBox>
  );
}


PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageLayout;
