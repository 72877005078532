import { useState } from "react";
import jwt from "jsonwebtoken";

const getToken = () => {
  const tokenString = localStorage.getItem("token");
  return JSON.parse(tokenString);
};

function getTokenExpirationDate(encodedToken) {
  let token = jwt.decode(encodedToken, { complete: true });

  if (!token.payload.exp) {
    return null;
  }

  let date = new Date(0);
  date.setUTCSeconds(token.payload.exp);
  return date;
}

function isTokenExpired(token) {
  return getTokenExpirationDate(token) < new Date();
}

export function getUsername() {
  const token = getToken();
  if (!token) return null;
  return jwt.decode(token, { complete: true }).payload.username;
}

export default function useToken() {
  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
    localStorage.setItem("token", JSON.stringify(userToken));
    setToken(userToken.token);
  };

  function isLogged() {
    return !!token && !isTokenExpired(token);
  }

  return {
    isLogged,
    setToken: saveToken,
  };
}

export async function WebGetRequest(endpoint) {
  return await fetch(endpoint, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  }).then((response) => response.json());
}

export async function WebPostRequest(endpoint, data) {
  return await fetch(endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify(data),
  });
}