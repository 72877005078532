import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import VuiBox from "components/VuiBox";

import Breadcrumbs from "../../Breadcrumbs";

import {
  navbar,
  navbarContainer,
  navbarIconButton,
  navbarMobileMenu,
  navbarRow,
} from "./styles";

import { setMiniSidenav, setTransparentNavbar, useVisionUIController } from "context";
import Menu from "@mui/material/Menu";
import NotificationItem from "../../Items/NotificationItem";
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import VuiButton from "../../../components/VuiButton";
import { getUsername, WebPostRequest } from "../../../api/request";

function DashboardNavbar({ title, absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const [openDialog, setDialogOpen] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);

  useEffect(() => {

    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }


    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    window.addEventListener("scroll", handleTransparentNavbar);


    handleTransparentNavbar();


    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const handleClickPassword = () => {
    handleCloseMenu();
    setDialogOpen(true);
  };
  const handleDialogClose = () => setDialogOpen(false);


  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        color="text"
        image={
          <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>
            settings
          </Icon>
        }
        title={["", "Change Password"]}
        onClick={handleClickPassword}
      />
      <NotificationItem
        color="text"
        image={
          <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>
            logout
          </Icon>
        }
        title={["", "Logout"]}
        onClick={() => {
          localStorage.removeItem("token");
          window.location.reload();
        }}
      />
    </Menu>
  );

  if (title === undefined)
    title = route[route.length - 1];

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <VuiBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={title} route={route} light={light} />
        </VuiBox>
        {isMini ? null : (
          <VuiBox sx={(theme) => navbarRow(theme, { isMini })}>
            <VuiBox color={light ? "white" : "inherit"}>
              <IconButton
                size="small"
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <Icon>settings</Icon>
              </IconButton>
              {renderMenu()}
              <Dialog
                open={openDialog}
                onClose={handleDialogClose}
                PaperProps={{
                  component: "form",
                  onSubmit: (event) => {
                    event.preventDefault();

                    (async () => {
                      const response = await WebPostRequest("/api/users/change-password", {
                        username: getUsername(),
                        current_password: event.target.current_password.value,
                        new_password: event.target.new_password.value,
                        confirm_password: event.target.confirm_password.value,
                      });

                      const json = await response.json();
                      if (response.ok) {
                        alert(json.message);
                        handleDialogClose();
                      } else {
                        alert(json.message);
                      }
                    })();
                  },
                  sx: { backgroundColor: "#121212", color: "#fff" },
                }}
              >
                <DialogTitle color="#fff">Change Password</DialogTitle>
                <DialogContent>
                  <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="current_password"
                    name="current_password"
                    label="Current Password"
                    placeholder="Current password"
                    type="password"
                    fullWidth
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    required
                    margin="dense"
                    id="new_password"
                    name="new_password"
                    label="New Password"
                    placeholder="New password"
                    type="password"
                    fullWidth
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    required
                    margin="dense"
                    id="confirm_password"
                    name="confirm_password"
                    label="Confirm Password"
                    placeholder="New password"
                    type="password"
                    fullWidth
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                  />
                </DialogContent>
                <DialogActions>
                  <VuiButton variant="contained" color="error" onClick={handleDialogClose}>
                    Cancel
                  </VuiButton>
                  <VuiButton variant="contained" color="info" type="submit">
                    Change
                  </VuiButton>
                </DialogActions>
              </Dialog>
              <IconButton
                size="small"
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon className={"text-white"}>{miniSidenav ? "menu_open" : "menu"}</Icon>
              </IconButton>
            </VuiBox>
          </VuiBox>
        )}
      </Toolbar>
    </AppBar>
  );
}


DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
