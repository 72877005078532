import Footer from "../../layouts/Footer";

import DashboardLayout from "../../layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../layouts/Navbars/DashboardNavbar";
import VuiBox from "components/VuiBox";
import Card from "@mui/material/Card";
import VuiTypography from "components/VuiTypography";
import DataTable from "../../layouts/Tables/DataTable";
import React from "react";
import { WebGetRequest } from "api/request";
import { prettierRows } from "../../utils/prettier";

function Session(props) {
  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      setRows(prettierRows(await WebGetRequest("/api/session/" + props.match.params.ip)));
    })();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <VuiBox mb={3}>
          <Card>
            <DataTable title="Sessions" columns={[
              { name: "id", align: "center" },
              { name: "username", align: "center" },
              { name: "connect", align: "center" },
              { name: "disconnect", align: "center" },
              { name: "playtime", align: "center" },
              { name: "version", align: "center" },
            ]} rows={rows} />
          </Card>
        </VuiBox>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Session;
