import Link from "@mui/material/Link";
import React from "react";

export function createLink(to, data) {
  return <Link href={to} color={"inherit"} underline={"none"}>{data}</Link>;
}

const UNITS = [
  { seconds: 365 * 24 * 60 * 60, short: "y" },
  { seconds: 30 * 24 * 60 * 60, short: "mo" },
  { seconds: 7 * 24 * 60 * 60, short: "w" },
  { seconds: 24 * 60 * 60, short: "d" },
  { seconds: 60 * 60, short: "h" },
  { seconds: 60, short: "m" },
  { seconds: 1, short: "s" },
];

function formatDuration(seconds) {
  let remainingSeconds = seconds;
  let output = [];
  let outputSize = 0;

  for (let { seconds: unitSeconds, short } of UNITS) {
    let amount = Math.floor(remainingSeconds / unitSeconds);
    if (amount > 0) {
      remainingSeconds -= amount * unitSeconds;
      output.push(`${amount}${short}`);
      outputSize++;
    }
    if (remainingSeconds <= 0 || outputSize >= Infinity) {
      break;
    }
  }

  if (outputSize === 0) {
    return `0s`;
  }
  return output.join(" ");
}

export const protocolToVersionMap = {
  767: "1.21",
  766: "1.20.6",
  765: "1.20.4",
  764: "1.20.2",
  763: "1.20.1",
  762: "1.19.4",
  761: "1.19.3",
  760: "1.19.2",
  759: "1.19",
  758: "1.18.2",
  757: "1.18.1",
  756: "1.17.1",
  755: "1.17",
  754: "1.16.5",
  753: "1.16.3",
  751: "1.16.2",
  736: "1.16.1",
  735: "1.16",
  578: "1.15.2",
  575: "1.15.1",
  573: "1.15",
  498: "1.14.4",
  490: "1.14.3",
  485: "1.14.2",
  480: "1.14.1",
  477: "1.14",
  404: "1.13.2",
  401: "1.13.1",
  393: "1.13",
  340: "1.12.2",
  338: "1.12.1",
  335: "1.12",
  316: "1.11.2",
  315: "1.11",
  210: "1.10.2",
  110: "1.9.4",
  109: "1.9.2",
  108: "1.9.1",
  107: "1.9",
  47: "1.8.9",
  5: "1.7.10",
};

export function prettierRows(rows) {
  rows.forEach((row) => {
    row["username"] = createLink("/player/" + row["uuid"], row["username"]);

    if (row["uuid"] != null) {
      row["uuid"] = createLink("/player/" + row["uuid"], row["uuid"]);
    }

    if (row["server"] != null) {
      row["server"] = createLink("/server/" + row["server"], row["server"]);
    }

    if (row["depart"] != null) {
      row["depart"] = createLink("/server/" + row["depart"], row["depart"]);
      row["arrive"] = createLink("/server/" + row["arrive"], row["arrive"]);
    }

    if (row["ip"] != null) {
      row["ip"] = createLink("/session/" + row["ip"], row["ip"]);
    }

    if (row["date"] != null) {
      row["date"] = new Date(row["date"]).toLocaleString();
    } else if (row["connect"] != null) {
      row["connect"] = new Date(row["connect"]).toLocaleString();
      row["disconnect"] = new Date(row["disconnect"]).toLocaleString();
    }

    if (row["playtime"] != null) {
      row["playtime"] = formatDuration(row["playtime"]);
    }

    if (row["version"] != null) {
      row["version"] = protocolToVersionMap[row["version"]] || row["version"];
    }
  });
  return rows;
}