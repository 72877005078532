import Dashboard from "./pages/dashboard";
import Tables from "./pages/tables";
import Player from "./pages/player";
import Server from "./pages/server";
import Session from "./pages/session";

import { IoHome, IoStatsChart } from "react-icons/io5";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <IoHome size="15px" color="inherit" />,
    component: Dashboard,
    noCollapse: true,
    visible: true,
  },
  { type: "divider", key: "divider-1", visible: true },
  {
    type: "collapse",
    name: "Players",
    key: "players",
    route: "/players",
    icon: <IoStatsChart size="15px" color="inherit" />,
    component: Tables,
    noCollapse: true,
    visible: true,
  },
  {
    type: "collapse",
    name: "Messages",
    key: "chat",
    route: "/chat",
    icon: <IoStatsChart size="15px" color="inherit" />,
    component: Tables,
    noCollapse: true,
    visible: true,
  },
  {
    type: "collapse",
    name: "Commands",
    key: "command",
    route: "/command",
    icon: <IoStatsChart size="15px" color="inherit" />,
    component: Tables,
    noCollapse: true,
    visible: true,
  },
  {
    type: "collapse",
    name: "Servers",
    key: "server",
    route: "/server",
    icon: <IoStatsChart size="15px" color="inherit" />,
    component: Tables,
    noCollapse: true,
    visible: true,
  },
  {
    type: "collapse",
    name: "Sessions",
    key: "session",
    route: "/session",
    icon: <IoStatsChart size="15px" color="inherit" />,
    component: Tables,
    noCollapse: true,
    visible: true,
  },
  {
    key: "player_info",
    route: "/player/:uuid",
    component: Player,
    visible: false,
  },
  {
    key: "server_info",
    route: "/server/:name",
    component: Server,
    visible: false,
  },
  {
    key: "session_info",
    route: "/session/:ip",
    component: Session,
    visible: false,
  },
];

export default routes;
