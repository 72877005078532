import React, { useState } from "react";

import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import GradientBorder from "../../layouts/GradientBorder";

import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

import CoverLayout from "../../layouts/LayoutContainers/CoverLayout";
import PropTypes from "prop-types";
import { WebPostRequest } from "api/request";
import { Link } from "react-router-dom";

function Login({ setToken }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await WebPostRequest("/api/users/login", {
        username: username,
        password: password,
      });

      const data = await response.json();
      if (response.ok) {
        setToken(data.token);
        window.location.href = "/";
      } else {
        setErrorMessage(data.message);
      }
    } catch (error) {
      setErrorMessage("An error occurred");
    }
  };

  return (
    <CoverLayout title="Login" color="white">
      <VuiBox component="form" role="form" onSubmit={handleLogin}>
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Username
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            padding="1px"
            borderRadius={borders.borderRadius.lg}
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle,
            )}
          >
            <VuiInput
              type="text"
              placeholder="Your username..."
              icon={{ component: false, direction: "none" }}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </GradientBorder>
        </VuiBox>
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Password
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle,
            )}
          >
            <VuiInput
              type="password"
              placeholder="Your password..."
              icon={{ component: false, direction: "none" }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </GradientBorder>
        </VuiBox>
        {errorMessage && (
          <VuiBox mt={2}>
            <VuiTypography variant="body2" color="error">
              {errorMessage}
            </VuiTypography>
          </VuiBox>
        )}
        <VuiBox mt={4} mb={1}>
          <VuiButton variant="contained" color="info" fullWidth type="submit">
            LOGIN
          </VuiButton>
        </VuiBox>
        <VuiBox mt={3} textAlign="center">
          <VuiTypography variant="button" color="text" fontWeight="regular">
            Don&apos;t have an account?{" "}
            <VuiTypography
              component={Link}
              to="/register"
              variant="button"
              color="white"
              fontWeight="medium"
            >
             Register
            </VuiTypography>
          </VuiTypography>
        </VuiBox>
      </VuiBox>
    </CoverLayout>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};

export default Login;