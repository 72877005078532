import React, { useMemo, useState } from "react";


import PropTypes from "prop-types";


import { v4 as uuidv4 } from "uuid";


import { Table as MuiTable } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import VuiBox from "components/VuiBox";
import VuiAvatar from "components/VuiAvatar";
import VuiTypography from "components/VuiTypography";

import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
import VuiPagination from "../../../components/VuiPagination";
import VuiSelect from "../../../components/VuiSelect";

import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import VuiInput from "../../../components/VuiInput";

function DataTable({ title, columns, rows }) {
  const { grey } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;

  const [sortConfig, setSortConfig] = useState({ key: "id", direction: "desc" });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [search, setSearch] = useState("");

  const filteredRows = useMemo(() => {
    if (!search) return rows;
    return rows.filter((row) =>
      columns.some((col) => {
        if (row[col.name]?.props?.children) {
          return row[col.name].props?.children?.toString().toLowerCase().includes(search.toLowerCase());
        }
        row[col.name].toString().toLowerCase().includes(search.toLowerCase())
      })
    );
  }, [rows, columns, search]);

  const sortedRows = useMemo(() => {
    if (sortConfig.direction === "default") {
      return filteredRows;
    }
    if (sortConfig.key) {
      return [...filteredRows].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return filteredRows;
  }, [filteredRows, sortConfig]);

  const handleSort = (column) => {
    let direction = "asc";
    if (sortConfig.key === column && sortConfig.direction === "asc") {
      direction = "desc";
    } else if (sortConfig.key === column && sortConfig.direction === "desc") {
      direction = "default";
    }
    setSortConfig({ key: column, direction });
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(parseInt(event.value, 10));
    setCurrentPage(1);
  };

  const renderColumns = columns.map(({ name, align, width }, key) => {
    let pl;
    let pr;

    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === columns.length - 1) {
      pl = 3;
      pr = 3;
    } else {
      pl = 1;
      pr = 1;
    }

    return (
      <VuiBox
        key={name}
        component="th"
        width={width || "auto"}
        pt={1.5}
        pb={1.25}
        pl={align === "left" ? pl : 3}
        pr={align === "right" ? pr : 3}
        textAlign={align}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        color="text"
        opacity={0.7}
        borderBottom={`${borderWidth[1]} solid ${grey[700]}`}
        onClick={() => handleSort(name)}
      >
        {name.toUpperCase()}
        <IconButton
          size="small"
          sx={{ p: 0 }}
          color="inherit"
        >
          <Icon color="inherit">
            {sortConfig.key === name && sortConfig.direction === "asc"
              ? "keyboard_arrow_up"
              : sortConfig.key === name && sortConfig.direction === "desc"
                ? "keyboard_arrow_down"
                : ""}
          </Icon>
        </IconButton>
      </VuiBox>
    );
  });

  const paginatedRows = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return sortedRows.slice(startIndex, startIndex + itemsPerPage);
  }, [sortedRows, currentPage, itemsPerPage]);

  const renderRows = paginatedRows.map((row, key) => {
    const rowKey = `row-${key}`;

    const tableRow = columns.map(({ name, align }) => {
      let template;

      if (Array.isArray(row[name])) {
        template = (
          <VuiBox
            key={uuidv4()}
            component="td"
            p={1}
            borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null}
          >
            <VuiBox display="flex" alignItems="center" py={0.5} px={1}>
              <VuiBox mr={2}>
                <VuiAvatar src={row[name][0]} name={row[name][1]} variant="rounded" size="sm" />
              </VuiBox>
              <VuiTypography
                color="white"
                variant="button"
                fontWeight="medium"
                sx={{ width: "max-content" }}
              >
                {row[name][1]}
              </VuiTypography>
            </VuiBox>
          </VuiBox>
        );
      } else {
        template = (
          <VuiBox
            key={uuidv4()}
            component="td"
            p={1}
            textAlign={align}
            borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${grey[700]}` : null}
          >
            <VuiTypography
              variant="button"
              fontWeight="regular"
              color="text"
              sx={{ display: "inline-block"}}
            >
              {row[name]}
            </VuiTypography>
          </VuiBox>
        );
      }

      return template;
    });

    return <TableRow key={rowKey}>{tableRow}</TableRow>;
  });

  const totalPages = Math.ceil(filteredRows.length / itemsPerPage);

  const pagination = (
    <VuiBox mt={2} display="flex" justifyContent="space-between" alignItems="center">
      <VuiTypography
        variant="button"
        fontWeight="regular"
        color="white"
        sx={{ p: 1 }}
      >
        Showing {(currentPage === 1 ? 1 : (currentPage - 1) * itemsPerPage)} to {Math.min(currentPage * itemsPerPage, rows.length)} of {rows.length} entries
      </VuiTypography>
      <VuiPagination variant="gradient" color="info">
        <VuiPagination item onClick={() => handlePageChange(null, 1)}>
          <Icon>keyboard_arrow_left</Icon>
        </VuiPagination>
        {[...Array(totalPages)].map((_, index) => (
          <VuiPagination
            key={index + 1}
            item
            active={currentPage === index + 1}
            onClick={() => handlePageChange(null, index + 1)}
          >
            {index + 1}
          </VuiPagination>
        ))}
        <VuiPagination item onClick={() => handlePageChange(null, totalPages)}>
          <Icon>keyboard_arrow_right</Icon>
        </VuiPagination>
      </VuiPagination>
    </VuiBox>
  );

  return (
    <VuiBox>
      <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="22px">
        {title && (
          <VuiTypography variant="lg" color="white" textTransform="capitalize">
            {title}
          </VuiTypography>
        )}
        <VuiBox pr={1}>
          <VuiInput
            placeholder="Type here..."
            icon={{ component: "search", direction: "left" }}
            sx={({ breakpoints }) => ({
              [breakpoints.down("sm")]: {
                maxWidth: "80px",
              },
              [breakpoints.only("sm")]: {
                maxWidth: "80px",
              },
              backgroundColor: "info.main !important",
            })}
            onChange={(e) => setSearch(e.target.value)}
          />
        </VuiBox>
      </VuiBox>
      <VuiBox
        sx={{
          "& th": {
            borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
              `${borderWidth[1]} solid ${grey[700]}`,
          },
          "& .MuiTableRow-root:not(:last-child)": {
            "& td": {
              borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                `${borderWidth[1]} solid ${grey[700]}`,
            },
          },
        }}
      >
        <VuiBox>
          <VuiBox display="flex" alignItems="center" mb={2}>
            <VuiSelect
              options={[
                { value: "10", label: "10" },
                { value: "20", label: "20" },
                { value: "50", label: "50" },
                { value: "100", label: "100" },
              ]}
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
            />
            <VuiTypography
              variant="button"
              fontWeight="regular"
              color="text"
              sx={{ display: "inline-block", width: "max-content", p: 1 }}
            >
              entries per page
            </VuiTypography>
          </VuiBox>
          <TableContainer>
            <MuiTable>
              <VuiBox component="thead">
                <TableRow>{renderColumns}</TableRow>
              </VuiBox>
              <TableBody>{renderRows}</TableBody>
            </MuiTable>
          </TableContainer>
          {pagination}
        </VuiBox>
      </VuiBox>
    </VuiBox>
  );
}


DataTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
};

export default DataTable;
