import Footer from "../../layouts/Footer";

import DashboardLayout from "../../layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../layouts/Navbars/DashboardNavbar";
import React from "react";
import VuiBox from "components/VuiBox";
import Card from "@mui/material/Card";
import DataTable from "../../layouts/Tables/DataTable";
import { WebGetRequest } from "api/request";
import { prettierRows } from "../../utils/prettier";
import Grid from "@mui/material/Grid";
import MiniStatisticsCard from "../../layouts/Cards/StatisticsCards/MiniStatisticsCard";
import { IoDocumentText, IoGlobe } from "react-icons/io5";
import { FaShoppingCart } from "react-icons/fa";
import Calendar from "../../layouts/Calendar";

function Player(props) {
  const [rows, setRows] = React.useState({
    player: [],
    chat: [],
    command: [],
    server: [],
    session: [],
  });
  const [data, setData] = React.useState({
    countMessages: 0,
    countCommands: 0,
    countSwitches: 0,
    countSessions: 0,
  });

  React.useEffect(() => {
    (async () => {
      setRows(await WebGetRequest("/api/player/" + props.match.params.uuid));
      setData(await WebGetRequest("/api/stats/" + props.match.params.uuid));
    })();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar title={rows.player.username} />
      <VuiBox py={0}>
        <VuiBox mb={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Messages" }}
                count={data.countMessages}
                icon={{ color: "info", component: <IoGlobe size="22px" color="white" /> }}
              />
            </Grid>
            <Grid item xs={12} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Commands" }}
                count={data.countCommands}
                icon={{ color: "info", component: <IoDocumentText size="22px" color="white" /> }}
              />
            </Grid>
            <Grid item xs={12} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Switches" }}
                count={data.countSwitches}
                icon={{ color: "info", component: <FaShoppingCart size="20px" color="white" /> }}
              />
            </Grid>
            <Grid item xs={12} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Sessions" }}
                count={data.countSessions}
                icon={{ color: "info", component: <FaShoppingCart size="20px" color="white" /> }}
              />
            </Grid>
          </Grid>
        </VuiBox>
      </VuiBox>
      <VuiBox py={2}>
        <VuiBox mb={2}>
          <Card>
            <DataTable title="Sessions" columns={[
              { name: "id", align: "center" },
              { name: "ip", align: "center" },
              { name: "connect", align: "center" },
              { name: "disconnect", align: "center" },
              { name: "playtime", align: "center" },
              { name: "version", align: "center" },
            ]} rows={prettierRows(rows.session)} />
          </Card>
        </VuiBox>
      </VuiBox>
      <VuiBox py={2}>
        <VuiBox mb={2}>
          <Card>
            <DataTable title="Messages" columns={[
              { name: "id", align: "center" },
              { name: "message", align: "center" },
              { name: "server", align: "center" },
              { name: "date", align: "center" },
            ]} rows={prettierRows(rows.chat)} />
          </Card>
        </VuiBox>
      </VuiBox>
      <VuiBox py={2}>
        <VuiBox mb={2}>
          <Card>
            <DataTable title="Commands" columns={[
              { name: "id", align: "center" },
              { name: "command", align: "center" },
              { name: "server", align: "center" },
              { name: "date", align: "center" },
            ]} rows={prettierRows(rows.command)} />
          </Card>
        </VuiBox>
      </VuiBox>
      <VuiBox py={2}>
        <VuiBox mb={2}>
          <Card>
            <DataTable title="Server Switches" columns={[
              { name: "id", align: "center" },
              { name: "depart", align: "center" },
              { name: "arrive", align: "center" },
              { name: "date", align: "center" },
            ]} rows={prettierRows(rows.server)} />
          </Card>
        </VuiBox>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Player;
