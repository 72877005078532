import Grid from "@mui/material/Grid";
import { Card } from "@mui/material";

import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

import DashboardLayout from "../../layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../layouts/Navbars/DashboardNavbar";
import Footer from "../../layouts/Footer";
import MiniStatisticsCard from "../../layouts/Cards/StatisticsCards/MiniStatisticsCard";

import { IoDocumentText, IoGlobe, IoWallet } from "react-icons/io5";
import { FaShoppingCart } from "react-icons/fa";

import LineChart from "../../layouts/Charts/LineCharts/LineChart";
import React from "react";
import { WebGetRequest } from "api/request";
import PieChart from "../../layouts/Charts/PieCharts/PieChart";
import { protocolToVersionMap } from "../../utils/prettier";

function Dashboard() {
  const [data, setData] = React.useState({
    registerPlayers: 0,
    countSeconds: 0,
    countMessages: 0,
    countCommands: 0,
    countSwitches: 0,
    countSessions: 0,
    charts: {
      players: {},
      versions: {},
    },
  });

  React.useEffect(() => {
    (async () => {
      setData(await WebGetRequest("/api/stats"));
    })();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <VuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} xl={2}>
              <MiniStatisticsCard
                title={{ text: "Players", fontWeight: "regular" }}
                count={data.registerPlayers}
                icon={{ color: "info", component: <IoWallet size="22px" color="white" /> }}
              />
            </Grid>
            <Grid item xs={12} xl={2}>
              <MiniStatisticsCard
                title={{ text: "Hours Played", fontWeight: "regular" }}
                count={Math.round((data.countSeconds / 3600) * 10) / 10}
                icon={{ color: "info", component: <IoWallet size="22px" color="white" /> }}
              />
            </Grid>
            <Grid item xs={12} xl={2}>
              <MiniStatisticsCard
                title={{ text: "Messages" }}
                count={data.countMessages}
                icon={{ color: "info", component: <IoGlobe size="22px" color="white" /> }}
              />
            </Grid>
            <Grid item xs={12} xl={2}>
              <MiniStatisticsCard
                title={{ text: "Commands" }}
                count={data.countCommands}
                icon={{ color: "info", component: <IoDocumentText size="22px" color="white" /> }}
              />
            </Grid>
            <Grid item xs={12} xl={2}>
              <MiniStatisticsCard
                title={{ text: "Switches" }}
                count={data.countSwitches}
                icon={{ color: "info", component: <FaShoppingCart size="20px" color="white" /> }}
              />
            </Grid>
            <Grid item xs={12} xl={2}>
              <MiniStatisticsCard
                title={{ text: "Sessions" }}
                count={data.countSessions}
                icon={{ color: "info", component: <FaShoppingCart size="20px" color="white" /> }}
              />
            </Grid>
          </Grid>
        </VuiBox>
        <VuiBox mb={3}>
          <Grid container spacing="18px">
            <Grid item xs={12} lg={12} xl={12}>
              <Card>
                <VuiBox sx={{ height: "100%" }}>
                  <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
                    Registered Players
                  </VuiTypography>
                  <VuiBox sx={{ height: "310px" }}>
                    {data.charts.players.data && (
                      <LineChart
                        lineChartData={[data.charts.players]}
                        lineChartOptions={{
                          chart: {
                            toolbar: {
                              show: false,
                            },
                          },
                          tooltip: {
                            theme: "dark",
                          },
                          dataLabels: {
                            enabled: false,
                          },
                          stroke: {
                            curve: "smooth",
                          },
                          xaxis: {
                            type: "datetime",
                            categories: data.charts.players.categories.map(dateString => {
                              const date = new Date(dateString);
                              return date.getDay() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
                            }),
                            labels: {
                              style: {
                                colors: "#c8cfca",
                                fontSize: "10px",
                              },
                            },
                            axisBorder: {
                              show: false,
                            },
                            axisTicks: {
                              show: false,
                            },
                          },
                          yaxis: {
                            labels: {
                              style: {
                                colors: "#c8cfca",
                                fontSize: "10px",
                              },
                            },
                          },
                          legend: {
                            show: false,
                          },
                          grid: {
                            strokeDashArray: 5,
                            borderColor: "#56577A",
                          },
                          fill: {
                            type: "gradient",
                            gradient: {
                              shade: "dark",
                              type: "vertical",
                              shadeIntensity: 0,
                              gradientToColors: undefined,
                              inverseColors: true,
                              opacityFrom: 0.8,
                              opacityTo: 0,
                              stops: [],
                            },
                            colors: ["#0075FF"],
                          },
                          colors: ["#0075FF"],
                        }}
                      />
                    )}
                  </VuiBox>
                </VuiBox>
              </Card>
            </Grid>
          </Grid>
        </VuiBox>
        <VuiBox mb={3}>
          <Grid container spacing="18px">
            <Grid item xs={12} lg={12} xl={12}>
              <Card>
                <VuiBox sx={{ height: "100%" }}>
                  <VuiTypography variant="lg" color="white" fontWeight="bold" mb="5px">
                    Players' Version
                  </VuiTypography>
                  <VuiBox sx={{ height: "310px" }}>
                    {data.charts.versions.data && (
                      <PieChart
                        chartData={data.charts.versions.data}
                        chartOptions={{
                          chart: {
                            toolbar: {
                              show: false,
                            },
                          },
                          tooltip: {
                            theme: "dark",
                          },
                          dataLabels: {
                            enabled: false,
                          },
                          grid: {
                            strokeDashArray: 5,
                            borderColor: "#56577A",
                          },
                          labels: data.charts.versions.names.map(ver => protocolToVersionMap[ver]),
                          responsive: [{
                            breakpoint: 480,
                            options: {
                              chart: {
                                width: 200
                              },
                              legend: {
                                position: 'bottom'
                              }
                            }
                          }]
                        }}
                      />
                    )}
                  </VuiBox>
                </VuiBox>
              </Card>
            </Grid>
          </Grid>
        </VuiBox>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
