import Card from "@mui/material/Card";


import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";


import DashboardLayout from "../../layouts/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../layouts/Navbars/DashboardNavbar";
import Footer from "../../layouts/Footer";

import React from "react";
import { useLocation } from "react-router-dom";
import DataTable from "../../layouts/Tables/DataTable";
import VuiInput from "components/VuiInput";
import { WebGetRequest } from "api/request";
import { prettierRows } from "../../utils/prettier";

function Tables(props) {
  const column_section = {
    "/players": [
      { name: "id", align: "center" },
      { name: "username", align: "center" },
      { name: "uuid", align: "center" },
    ],
    "/chat": [
      { name: "id", align: "center" },
      { name: "username", align: "center" },
      { name: "message", align: "center" },
      { name: "server", align: "center" },
      { name: "date", align: "center" },
    ],
    "/command": [
      { name: "id", align: "center" },
      { name: "username", align: "center" },
      { name: "command", align: "center" },
      { name: "server", align: "center" },
      { name: "date", align: "center" },
    ],
    "/server": [
      { name: "id", align: "center" },
      { name: "username", align: "center" },
      { name: "depart", align: "center" },
      { name: "arrive", align: "center" },
      { name: "date", align: "center" },
    ],
    "/session": [
      { name: "id", align: "center" },
      { name: "username", align: "center" },
      { name: "ip", align: "center" },
      { name: "connect", align: "center" },
      { name: "disconnect", align: "center" },
      { name: "playtime", align: "center" },
      { name: "version", align: "center" },
    ],
  };

  const columns = column_section[props.match.path];

  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      setRows(prettierRows(await WebGetRequest("/api/" + props.match.path)));
    })();
  }, []);

  const route = useLocation().pathname.split("/").slice(1);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <VuiBox mb={3}>
          <Card>
            <DataTable title={route[route.length - 1]} columns={columns} rows={rows} />
          </Card>
        </VuiBox>
      </VuiBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
