import React, { useState } from "react";

import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiButton from "components/VuiButton";
import GradientBorder from "../../layouts/GradientBorder";

import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

import CoverLayout from "../../layouts/LayoutContainers/CoverLayout";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { WebPostRequest } from "api/request";
import { Link } from "react-router-dom";

function Register() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [otpMenu, setOtpMenu] = useState(false);
  const [otp, setOtp] = useState("");

  const handleRegister = async (event) => {
    event.preventDefault();
    try {
      const response = await WebPostRequest("/api/users/register", {
        username: username,
        password: password,
        confirm_password: confirm_password,
      });

      const data = await response.json();
      if (response.ok) {
        setErrorMessage("");
        setOtp(data.otp)
        handleOtpMenu();
      } else {
        setErrorMessage(data.message);
      }
    } catch (error) {
      setErrorMessage("An error occurred");
    }
  };

  const handleOtpMenu = () => {
    setOtpMenu(!otpMenu);
  };

  const renderOptDialog = () => {
    return (
      <Dialog
        open={otpMenu}
        onClose={handleOtpMenu}
        PaperProps={{
          component: "form",
          onSubmit: () => {
            window.location.href = "/login";
          },
          sx: { backgroundColor: "#121212", color: "#fff" },
        }}
      >
        <DialogTitle>OTP</DialogTitle>
        <DialogContent>
          <VuiBox mb={2}>
            <VuiBox mb={1}>
              <VuiTypography variant="body2">
                Your OTP is: {otp}
              </VuiTypography>
            </VuiBox>
            <VuiBox mb={1}>
              <VuiTypography variant="body2">
                Execute this command in the console to verify your account:
              </VuiTypography>
              <VuiTypography variant="code">
                /dhv register {otp}
              </VuiTypography>
            </VuiBox>
          </VuiBox>
        </DialogContent>
        <DialogActions>
          <VuiButton variant="contained" color="info" type="submit">
            LOGIN
          </VuiButton>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <CoverLayout title="Register" color="white">
      <VuiBox component="form" role="form" onSubmit={handleRegister}>
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Username
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            padding="1px"
            borderRadius={borders.borderRadius.lg}
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle,
            )}
          >
            <VuiInput
              type="text"
              placeholder="Your username..."
              icon={{ component: false, direction: "none" }}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </GradientBorder>
        </VuiBox>
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Password
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle,
            )}
          >
            <VuiInput
              type="password"
              placeholder="Your password..."
              icon={{ component: false, direction: "none" }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </GradientBorder>
        </VuiBox>
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Confirm Password
            </VuiTypography>
          </VuiBox>
          <GradientBorder
            minWidth="100%"
            borderRadius={borders.borderRadius.lg}
            padding="1px"
            backgroundImage={radialGradient(
              palette.gradients.borderLight.main,
              palette.gradients.borderLight.state,
              palette.gradients.borderLight.angle,
            )}
          >
            <VuiInput
              type="password"
              placeholder="Confirm your password..."
              icon={{ component: false, direction: "none" }}
              value={confirm_password}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </GradientBorder>
        </VuiBox>
        {errorMessage && (
          <VuiBox mt={2}>
            <VuiTypography variant="body2" color="error">
              {errorMessage}
            </VuiTypography>
          </VuiBox>
        )}
        <VuiBox mt={4} mb={1}>
          <VuiButton variant="contained" color="info" fullWidth type="submit">
            REGISTER
          </VuiButton>
        </VuiBox>
        <VuiBox mt={3} textAlign="center">
          <VuiTypography variant="button" color="text" fontWeight="regular">
            Have an account?{" "}
            <VuiTypography
              component={Link}
              to="/login"
              variant="button"
              color="white"
              fontWeight="medium"
            >
             Login
            </VuiTypography>
          </VuiTypography>
        </VuiBox>
        {renderOptDialog()}
      </VuiBox>
    </CoverLayout>
  );
}

export default Register;